import React from "react"
import SEO from "../components/seo"
import { FaArrowRight } from 'react-icons/fa'

import "../scss/youmedico.scss"
import "../scss/companies.scss"

import HeaderLogoImg from "../assets/img/compsol/medico_1.svg"
import HeaderImg from "../assets/img/youMedico/headerImg.png"
import Telemedicine from "../assets/img/youMedico/telemedicine 1.png"
import EPR from "../assets/img/youMedico/ERP 1.png"
import RPM from "../assets/img/youMedico/PRM 1.png"
import AI from "../assets/img/youMedico/AI Symptons 1.png"
import GroupImg1 from "../assets/img/youMedico/Group1.png"
import GroupImg2 from "../assets/img/youMedico/Group2.png"
import Icon1 from "../assets/img/youMedico/Icon1.png"
import Icon2 from "../assets/img/youMedico/Icon2.png"
import Icon3 from "../assets/img/youMedico/Icon3.png"
import Icon4 from "../assets/img/youMedico/Icon4.png"
import Icon5 from "../assets/img/youMedico/Icon5.png"
import Icon6 from "../assets/img/youMedico/Icon6.png"
import Icon7 from "../assets/img/youMedico/Icon7.png"
import Icon8 from "../assets/img/youMedico/Icon8.png"
import TickIcon from "../assets/img/youMedico/tick.svg"
import SingleForm from "../components/singleForm"

const YouMedicoPage = () => {
    return (
        <div>
            <SEO title="Youmedico" keywords={[`youpal`, `youpal group`, `youMedico`]} />
            <div className="youMain youMedicoMain">
                <section className="container">
                    <div className="col">
                        <HeaderLogoImg className="headerLogo" />
                        <div className="internalMainHeader bugP">
                            <h2 className="internalNarrowCol">Go for Youmedico and let your patients’ number multiply </h2>
                        </div>
                        <img className="headerImg headerImgMobile" src={HeaderImg} />
                        <p className="internalNarrowCol internalMainHeaderText">
                        With Youmedico, virtual medical care has become even more sophisticated and efficient. We have created a solution that you can fully configure and customise for your needs. With a fully customisable functionality, essential features, and possibilities, we enable you to practise medicine in a modern way. 
</p>
                        <img className="headerImg headerImgDesktop" src={HeaderImg} />
                    </div>
                </section>

                <section className="container">
                    <div className="col">
                        <span className="internalNarrowCol internalSmallHeader">Solutions</span>
                        <h3 className="internalNarrowCol">
                            Our solution for your business
                        </h3>
                        <p className="internalNarrowCol">
                        Youmedico is a solution for modern telemedicine, where both patients and doctors have access to the necessary tools and features. Some of the core benefits of the platform are efficiency, simplicity, intuitive interface, and user-friendliness. 
</p>
                    </div>
                </section>

                <section className="container contactsSection">
                    <div className="row">
                        <div className="col">
                            <div><img src={Telemedicine} /></div>
                            <h4>Telemedicine</h4>
                            <p>
                                A modern approach to telemedicine, Youmedico is where virtual medical care is accessible through a SaaS solution, providing full customisation.
                            </p>
                        </div>
                        <div className="col">
                            <div><img src={EPR} /></div>
                            <h4>EPR</h4>
                            <p>
                            Our electronic patient record system gives healthcare providers the ability to integrate with existing EPR systems, through our API solution. </p>
                        </div>
                        <div className="col">
                            <div><img src={RPM} /></div>
                            <h4>RPM</h4>
                            <p>
                            Youmedico has put extra focus on creating an architecture for preventive care.
Connectivity through IoT enables the detection of anomalies and illnesses.

                            </p>
                        </div>
                        <div className="col">
                            <div><img src={AI} /></div>
                            <h4>AI Symptomes Check</h4>
                            <p>
                            Our AI system gives health care professionals the ability to get already analysed data. AI technology enables data collection, patient monitoring and analysis of the condition.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="container internalSection">
                    <div className="row TwoCol">
                        <div className="col">
                            <img className="sideImg" src={GroupImg1} />
                        </div>
                        <div className="col">
                            <div className="col sideContent internalAnalytics">
                                <div className="internalSmallSecondaryHeader text-left">Analytics</div>
                                <h3 className="text-left">Live health monitoring with certified tracking devices</h3>
                                <p>
                                YouMedico’s ambition is to build a platform for many different devices based on the understanding of new sensors and new applications. This will lead to new devices being introduced to the market. 
 </p>
                                <div className="row">
                                    <div className="col">
                                        <div className="squareHolder"><img src={Icon1} /></div>
                                        <h4>Youcloud IoT</h4>
                                        <p>
                                        Includes services and standards necessary for connecting, managing, and securing different IoT devices and applications.
                                        </p>
                                    </div>
                                    <div className="col">
                                        <div className="squareHolder"><img src={Icon2} /></div>
                                        <h4>Always in Sync</h4>
                                        <p>
                                        Healthcare records can be transferred to any EHR via API. Patients can engage and be monitored via RPM with their healthcare providers.
                                        </p>
                                    </div>
                                </div>
                                <div className="buttonArea">
                                    <a href="https://youmedico.com/" target="_blank">
                                        <button type="button" className="btn btn-primary px-4">
                                            <span>Find out more</span>
                                            <FaArrowRight style={{ fill: 'white' }} />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container exploreSection">
                    <div className="row">
                        <div className="col">
                            <h3>Explore our Youmedico features</h3>
                            
                            <div className="row internalPlatformSection">
                                <div className="exploreFeature">
                                    <div className="row">
                                        <div><img src={Icon3} /></div>
                                        <div className="col sideContent">
                                            <h4>HIPAA compliant</h4>
                                            <p>
                                                Compliant with GDPR and HIPAA. Following data privacy and security best practises and standards.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature">
                                    <div className="row">
                                        <div><img src={Icon4} /></div>
                                        <div className="col sideContent">
                                            <h4>Secure and fast</h4>
                                            <p>
                                                High-performant, high-available and  fault-tolerant infrastructure.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature">
                                    <div className="row">
                                        <div><img src={Icon5} /></div>
                                        <div className="col sideContent">
                                            <h4>Powerful dashboard</h4>
                                            <p>
                                                Data warehouses, Data lakes, Data streams and multiple Machine Learning tools integrated in one place.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                    <div className="row">
                                <div className="exploreFeature">
                                    <div className="row">
                                        <div><img src={Icon6} /></div>
                                        <div className="col sideContent">
                                            <h4>Cloud</h4>
                                            <p>
                                                Leveraging latest technologies, adopted by other Cloud vendors like AWS.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature">
                                    <div className="row">
                                        <div><img src={Icon7} /></div>
                                        <div className="col sideContent">
                                            <h4>Custom to fit </h4>
                                            <p>
                                                Patients can interact with the SaaS solution and book a doctor’s appointment.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature">
                                    <div className="row">
                                        <div><img src={Icon8} /></div>
                                        <div className="col sideContent">
                                            <h4>Custom domain</h4>
                                            <p>
                                                In this cloud-based application, data is stored and  analysed automatically.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container internalSection">
                    <div className="row TwoCol internalCollaborateSection">
                        <div className="col leftSide">
                            <div className="col sideContent">
                                <div className="internalSmallSecondaryHeader">Collaborate</div>
                                <h3>Why Youmedico is the best solution for your team anytime, anywhere</h3>
                                <p>
                                    Work with your team, manage your medical staff, and be flexible with appointments and your patients
                                </p>
                                <div className="row collaborationList">
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />Telemedicine</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />AI Symptomes  Checker</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />Remote patient  Monitoring</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />Insurance</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />Pharmacy</div>
                                </div>
                            </div>
                        </div>
                        <div className="col rightSide">
                            <img className="sideImg" src={GroupImg2} />
                        </div>
                    </div>
                </section>

                <section className="container internalSection integrationSection">
                    <div className="col">
                        <div className="internalSmallSecondaryHeader text-center">Benefits</div>
                        <h3 className="text-center integrationSectionTitle">Seamless integrations with<br /> other Youpal products</h3>
                        <p className="text-center integrationSectionText">
                            Youmedico can be fully synchronised and integrated with any other<br /> Youpal product without additional effort. 
                        </p>
                    </div>
                </section>

                <section className="container">
                    <div className="row contactBox">
                        <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=23" selector="contact-us-mautic-company-form" />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default YouMedicoPage
